<template>
    <div class='grid' :class='{ "mt-0": up }'>
        <div class='col-3'><span v-if='row.plan && row.plan.name'>{{row.plan.name}}</span>
            <span v-else>&nbsp;</span>
        </div>
        <div class='pl-1 col-5 bg-alter' v-if='row && row.plan'>(
            <strong>CB:</strong>
            {{row["external_id"]}}
            )
        </div>
        <div class='pl-1 col-1' v-if='!short && row && row.plan'>
                    <span v-if='row.plan && row.plan["price"]'>
                        {{formatPrice(row.plan["price"])}}
                    </span>
            <span v-else>&nbsp;</span>
        </div>
        <div class='pl-1 col-1 bg-alter'  v-if='row && row.plan'>
                    <span v-if='row.plan && row.plan["period"] && row.plan["period_unit"]'>
                        {{ row.plan["period"] + row.plan["period_unit"]}}
                    </span>
            <span v-else>&nbsp;</span>
        </div>
        <div class='pl-1 col-2'  v-if='row && row.plan'>
                    <span v-if='row.plan && row.plan["trial_period"] && row.plan["trial_period_unit"]'>
                        <strong>Trial: </strong>{{ row.plan["trial_period"] + row.plan["trial_period_unit"]}}
                    </span>
            <span v-else>&nbsp;</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['row','up', 'short'],
    computed: {
        dump(){
            return JSON.stringify(this.row);
        }
    },
    methods: {
        formatPrice(p){
            return (p / 100) + "€";
        }
    }
}
</script>
