<template>
    <div class='grid p-fluid formgrid'>
        <div class='col-12 field '>
                        <span class='p-float-label'>
                        <InputText id='email_searchline'
                                   type='text'
                                   v-model="searchLine"
                        />
                        <label for='email_searchline'>Comincia a digitare una mail</label>
                    </span>
        </div>
        <div class='col-12'>
            <DataTable
                       :value='rows'
                       v-model:selection="selectedUser"
                       selectionMode="single"
                       class='p-datatable-gridlines'
                       dataKey='id'
                       :rowHover='true'
                       :loading='loading'
                       responsiveLayout='scroll'
            >
                <template #empty>
                    <template v-if='user && user.email !== ""'>
                        <div class='grid p-fluid formgrid'>
                            <div class='col-6 field '>
                                <span class='p-float-label'>
                                    <InputText id="nus_last"
                                               type='text'
                                               v-model="user.last_name"
                                    />
                                    <label for="nus_last">Cognome</label>
                                </span>
                            </div>
                            <div class='col-6 field '>
                                <span class='p-float-label'>
                                    <InputText id="nus_email"
                                               type='text'
                                               v-model="user.email"
                                    />
                                    <label for="nus_email">Email</label>
                                </span>
                            </div>
                        </div>
                        <SaveButton @click.stop='saveNew' :label="'Aggiungi nuovo utente'" :load='loading'/>
                    </template>
                    <template v-else>
                        Nessun utente con questa email.
                    </template>
                </template>
                <template #loading>
                    Loading ... Please wait.
                </template>
                <Column field='id' header='ID' style='min-width:1rem'></Column>
                <Column field='email' header='Email' style='min-width:3rem'></Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Notifications from '../../mixins/notifications';
import tools from '../../libs/tools';
import SaveButton from './forms/SaveButton.vue';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Users/Users');

export default {
    components: { SaveButton },
    mixins: [ Notifications ],
    computed:{
        ...mapGetters(['rows', 'loading'])
    },
    emits: ['onChoose'],
    props: {
        resetOnChoose: {
            type: Boolean,
            default(){
                return false;
            }
        },
        delay: {
            type: Number,
            default(){
                return 2000;
            }
        }
    },
    data(){
        return {
            selectedUser: null,
            searchLine: "",
            last_mod: 0,
            user: null,
            search_params: {
                limit: 6,
                order: 1,
                sortBy: 'email',
                page: 1,
                email: '',
            },
        };
    },
    mounted(){
        this.searchLine = "";
        this.last_mod = new Date().getTime();
        this.clearSearch();
    },
    watch: {
        searchLine(n){
            this.last_mod = new Date().getTime();
            if (n && n.length > 3){
                setTimeout(this.delayedRefresh, this.delay);
            } else {
                this.clearSearch();
            }
        },
        selectedUser(n){
            this.choose(n);
        }
    },
    methods: {
        ...mapMutations(['setRows']),
        ...mapActions(['search', 'createPlain']),
        choose(data){
            this.$emit('onChoose', data);
            if (this.resetOnChoose){
                this.searchLine = "";
                this.clearSearch();
            }
        },
        clearSearch(){
            this.setRows([]);
            this.user = {
                email: "",
                last_name: "",
            };
        },
        delayedRefresh(){
            //Check if milliseconds passed
            if (new Date().getTime() - this.last_mod < this.delay){
                //Return
                return;
            }
            this.refreshList();
        },
        saveNew(){
            this.createPlain({
                ...this.user,
                first_name: "-"
            }).then(() => {
                this.refreshList();
            }).catch(err => {
                this.ShowStandardError(err.response);
            })
        },
        refreshList() {
            const payload = {
                ...this.search_params,
                email: this.searchLine,
            };
            this.search(payload).then().catch(err => {
                if (tools.models.validators.EMAIL(payload.email)){
                    this.user = {
                        email: payload.email,
                        last_name: "",
                    }
                }
                console.log(err);
                if (err.error === 'no-data'){
                    this.ShowWarning("Nope", "Nessun utente con questa email");
                    return;
                }
                this.ShowStandardError(err.response);
            });
        },
    }
}
</script>
