<template>
    <div class='grid' >
        <Dialog position='top'  header="JSON" v-model:visible="displayDetails" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" >
            <pre>{{details}}
            </pre>
            <template #footer>
                <Button label="Chiudi" icon="pi pi-times" @click="displayDetails=false" class="p-button-text"/>
            </template>
        </Dialog>
        <div class='col-12'>
            <h6>Questo utente è <InlineMessage class='ml-2' :style='{marginTop: "-7px"}' :severity='userStatus.color'>{{userStatus.label}}</InlineMessage></h6>
        </div>
        <div class='col-12'>
            <OverlayPanel ref="gift" :showCloseIcon="true" :dismissable="true" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '450px'}">
                <h6>Cerca il destinatario per email</h6>
                <SearchUser @onChoose='confirmMove($event)'></SearchUser>
            </OverlayPanel>
            <DataTable :value='rows' :paginator='true' class='p-datatable-gridlines' :rows='10' dataKey='id'
                       :rowHover='true'
                       :loading='loading'
                       responsiveLayout='scroll'
                       >
                <template #empty>
                    Non ci sono subscriptions da mostrare.
                </template>
                <template #loading>
                    Loading ... Please wait.
                </template>
                <Column field='id' header='ID' :sortable='true' style='min-width:1rem'></Column>
                <Column field='plan_id' header='Piano' style='min-width:2rem'>
                    <template #body='slotProps'>
                        <template v-if='editingId !== slotProps.data.id'>
                            {{ getPlanName(slotProps.data) }}
                        </template>
                        <template v-else>
                            <PlansDropdown :short='true' v-model='editingSub.plan_id' placeholder='Piano' />
                        </template>
                    </template>
                </Column>
                <Column field='plan_id' header='Prodotti' style='min-width:2rem'>
                    <template #body='slotProps'>
                        {{ getPlanProducts(slotProps.data) }}
                    </template>
                </Column>
                <Column field='plan_id' header='Trial' style='min-width:1rem'>
                    <template #body='slotProps'>
                        {{ getPlanTrial(slotProps.data) }}
                    </template>
                </Column>
                <Column field='plan_id' header='Provider' style='min-width:1rem'>
                    <template #body='slotProps'>
                        <i class='pi pi-info-circle mr-1 text-blue-600 cursor-pointer'
                           v-tooltip.top='"Prendi dettagli"' @click='getInfo(slotProps.data.id)'></i> {{ getPlanProvider(slotProps.data) }}
                        <i class='pi pi-refresh ml-1 text-orange-600 cursor-pointer' v-if='getPlanProvider(slotProps.data) === "Appstore"'
                           v-tooltip.top='"Aggiorna"' @click='refreshAppleSub(slotProps.data.id)'></i>
                    </template>
                </Column>
                <Column field='external_id' header='ID esterno' :sortable='true' style='min-width:2rem'>
                    <template #body='slotProps'>
                        <span :ref='"sub_"+slotProps.data.id'>{{slotProps.data['external_id']}}</span>
                    </template>
                </Column>
                <Column field='start' header='Creata' :sortable='true' style='min-width:1rem'>
                    <template #body='slotProps'>
                        {{ ts2date(slotProps.data.start) }}
                    </template>
                </Column>
                <Column field='expiry_at' header='Scadenza' :sortable='true'  style='min-width:1rem'>
                    <template #body='slotProps'>
                        <template v-if='editingId === slotProps.data.id'>
                            <Calendar
                                :showTime="true"
                                inputId="expiry_at"
                                v-model="editingSub.expiry"
                                dateFormat="dd/mm/yy" />
                        </template>
                        <template v-else>
                            {{ ts2date(slotProps.data.expiry_at) }}
                        </template>
                    </template>
                </Column>
                <Column field='status' header='Stato'  style='min-width:1rem'>
                    <template #body='slotProps'>
                        <InlineMessage :severity='getStatus(slotProps.data).color'>{{getStatus(slotProps.data).label}}</InlineMessage>
                    </template>
                </Column>
                <Column field='id' header='Azioni' style='min-width:2rem'>
                    <template #body='slotProps'>
                        <Button v-if="slotProps.data.id === editingId" icon='pi pi-check' class='mx-2 mb-2 p-button-success' title=''
                                @click='saveSub()' :loading='(savingSub===slotProps.data.id)' :class='{"p-spin": (savingSub===slotProps.data.id)}'></Button>
                        <Button v-if="slotProps.data.id === editingId" icon='pi pi-times' class='mr-2 mb-2 p-button-danger' title=''
                                @click='stopEditSub()'></Button>
                        <Button icon='pi pi-pencil' class='mr-2 mb-2 p-button-info' title='Modifica'
                                @click='editSub(slotProps.data)'></Button>
                        <font-awesome-icon @click='syncIntercomSub(slotProps.data.id)'
                                           class='text-blue-500 hover:text-blue-600 pointer mr-2'
                                           title='Sincronizza con Intercom'
                                           :style='{marginBottom: "3px"}'
                                           :icon="['fab', 'intercom']"
                                           size='3x'
                                           :spin='intercomSyncing===slotProps.data.id'/>
                        <font-awesome-icon v-if='getPlanProvider(slotProps.data) !== "Corporate"' @click='openTransferModal($event, slotProps.data)'
                                           class='text-orange-400 hover:text-orange-600 pointer mr-2'
                                           title='Regala ad altro utente'
                                           :style='{marginBottom: "3px"}'
                                           :icon="['fas', 'gift']"
                                           size='3x'
                                           :spin='movingSub===slotProps.data.id'/>
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Users/Users');
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import PlansDropdown from '../../../components/4books/forms/PlansDropdown';
import SearchUser from '../../../components/4books/SearchUser';

export default {
    components: {
        PlansDropdown, SearchUser
    },
    emits: ['changed'],
    data(){
        return {
            editingId: 0,
            editingSub: {},
            exportingSub: {},
            toUser: 0,
            displayDetails: false,
            details: null
        }
    },
    mixins: [ Navigations, Notifications ],
    computed: {
        ...mapGetters(['loading', 'intercomSyncing', 'savingSub', 'movingSub']),
        userStatus(){
            let statusVal = 0;
            let status = "FREE";
            let color = "info";
            if (this.rows){
                this.rows.forEach(sub => {
                    const data = this.getStatus(sub);
                    if (data.status_val >= statusVal){
                        status = data.status;
                        color = data.color;
                        statusVal = data.status_val;
                    }
                });
            }
            return {label: status, color: color};
        },
    },
    methods: {
        confirmMove(ev){
            this.$confirm.require({
                message: 'Sei sicura di voler trasferire questo abbonamento a '+ ev.email +'?',
                header: 'Richiesta di conferma',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when user confirms the action
                    this.moveSubscription({to: ev.id, sub: this.exportingSub })
                        .then(() => this.ShowSuccess("Fatto", "L'abbonamento è stato trasferito"))
                        .catch(err => {
                        this.ShowStandardError(err.response);
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                    this.onCloseTransferModal();
                }
            });
        },
        ...mapActions(['syncIntercomSub', 'updateUserSubscription', 'moveSubscription', 'getSubscriptionDetail', 'refreshAppstoreSubscription']),
        openTransferModal(event, sub){
            this.$refs.gift.show(event, this.$refs['sub_'+sub.id]);
            this.toUser = 0;
            this.exportingSub = sub;
        },

        onCloseTransferModal(){
            this.toUser = 0;
            this.exportingSub = {};
        },
        async saveSub(){
            this.updateUserSubscription(this.editingSub)
                .then(() => {
                    this.ShowSuccess('Ottimo', 'Subscription modificata con successo');
                    this.stopEditSub();
                })
                .catch(err => {
                this.ShowStandardError(err.response);
            });

        },
        async getInfo(sid){
            this.details = null;
            this.getSubscriptionDetail(sid)
                .then((res) => {
                    this.details = res;
                    this.displayDetails = true;
                })
                .catch(err => {
                    this.ShowStandardError(err.response);
                });

        },
        async refreshAppleSub(sid){
            this.details = null;
            this.refreshAppstoreSubscription(sid)
                .then((res) => {
                    if (res['done']) {
                        this.$emit('changed');
                        this.ShowSuccess("Fatto", "");
                    }
                    else this.ShowError("Attenzione", "Il server non ha confermato l'operazione");
                })
                .catch(err => {
                    this.ShowStandardError(err.response);
                });

        },
        convertToTree(res){
            return JSON.stringify(res);
        },
        editSub(sub){
            this.editingId = sub.id;
            this.editingSub = sub;
            this.editingSub.expiry = new Date(this.editingSub.expiry);
        },
        stopEditSub(){
          this.editingId = 0;
          this.editingSub = {};
        },
        synIntercom(id){
            this.syncIntercomSub(id).then(() => {
                this.ShowSuccess('FATTO', 'La sincronizzazione è in atto');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        getPlanName(data){
            if (data['plan'] && data['plan']['name']){
                return data['plan']['name'] + ' ('+data['plan']['period']+data['plan']['period_unit']+')';
            }
            return data.plan_id;
        },
        getPlanProducts(data){
            if (data['plan'] && data['plan']['products'] && data['plan']['products'].length){
                return data['plan']['products'].map(ps => ps.slug).join(", ")
            }
            return "No";
        },
        getPlanTrial(data){
            if (data['plan'] && data['plan']['trial_period']){
                return data['plan']['trial_period'] + ' ' + data['plan']['trial_period_unit'].toUpperCase();
            }
            return "No";
        },
        getPlanProvider(data){
            if (data['plan_provider'] && data['plan_provider']['name']){
                const str = data['plan_provider']['name'];
                return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            }
            return "-";
        },
        getStatus(data){
            const now = new Date();
            const expiry = new Date(data.expiry_at);
            let trialEnd = new Date(data.created_at);
            if (data['plan'] && data['plan']['trial_period']){
                if (data['plan']['trial_period_unit'] === 'd'){
                    trialEnd = trialEnd.setDate(trialEnd.getDate() + parseInt(data['plan']['trial_period'], 10));
                }
                if (data['plan']['trial_period_unit'] === 'm'){
                    trialEnd = trialEnd.setMonth(trialEnd.getMonth() + parseInt(data['plan']['trial_period'], 10));
                }
                if (data['plan']['trial_period_unit'] === 'y'){
                    trialEnd = trialEnd.setFullYear(trialEnd.getFullYear() + parseInt(data['plan']['trial_period'], 10));
                }
            }
            if (data.status === 2){
                //Expired or non-renewing
                if ( expiry < now ) return {label: "Cancellata", color: "error", status: "FREE", status_val: 0};
                //In trial?
                if (trialEnd > now) return {label: "In trial, non renewing", color: "warning", status: "Premium in Trial", status_val: 1};
                return {label: "Attiva, non renewing", color: "warning", status: "Premium non renewing", status_val: 2};
            }
            if (data.status === 1){
                if ( expiry < now ) return {label: "Scaduta ma NON cancellata", color: "help", status: "FREE (strange)", status_val: 0};
                if (trialEnd > now) return {label: "In trial", color: "success", status: "Premium in Trial", status_val: 1};
                return {label: "Attiva", color: "success", status: "Premium", status_val: 2};
            }
            //Altri stati
            return {label: "Sconosciuto", color: "warning", status: "FREE", status_val: 0};
        }
    },
    props: {
        rows: {
            type: Array,
            required: true,
            default(){
                return []
            }
        }
    }
}
</script>
