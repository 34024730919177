<template>
    <div>
        <Card>
            <template #title>
                <h5 v-if='id === ""'>Nuovo record</h5>
                <h5 v-else>{{ pageTitle }}</h5>
            </template>
            <template #content v-if='row'>
                <PlainForm/>
            </template>
        </Card>

        <Card class='mt-3'>
            <template #title>
                <h5>Abbonamenti</h5>
            </template>
            <template #content v-if='subs'>
                <Subscriptions :rows='subs' @changed='loadData(id)'/>
            </template>
        </Card>
    </div>

</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Users/Users');

import Factory from './model';
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import Guards from '../../../mixins/guards';

import PlainForm from './Plain';
import Subscriptions from './Subscriptions';

export default {
    beforeRouteLeave(to, from, next){
        this.beforeRouteLeaveCheck(to, from, next);
    },
    beforeRouteUpdate(to, from, next){
        this.beforeRouteUpdateCheck(to, from, next);
    },
    data(){
      return {};
    },
    mounted(){
        this.loadData(this.id);
    },
    computed: {
        ...mapGetters(['row', 'loading', 'dirtyForm', 'userSubscriptions']),
        subs(){
            return this.userSubscriptions(this.id) || [];
        },
        hasSubs(){
            return this.subs.length;
        }
    },
    methods: {
        ...mapActions(['getUserData']),
        ...mapMutations(['setRow', 'formIsClean']),
        loadData(id){
            if (!id){
                this.setRow(Factory.newModel(this.languages));
            } else {
                this.getUserData(id).then(res => {
                    let title = "Modifica dati utente ";
                    if (res.user) {
                        title += res.user['last_name'] + " (ID: " + this.id + ")";
                    }
                    this.setPageTitle(title);
                }).catch(err => {
                    this.ShowStandardError(err.response);
                }).finally(() => {});
            }

        }
    },
    props: {
        id: {
            default: 0,
            type: Number
        }
    },
    watch: {
        id(n, o){
            if (n !== o) {
                this.loadData(n);
            }
        }
    },
    mixins: [
        Notifications,
        Navigations,
        Guards
    ],
    components: {
        PlainForm,
        Subscriptions
    }
}
</script>
