import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Users/Users');

import Notifications from './notifications';

export default {
    computed: {
        ...mapGetters(['personifying', 'loading']),
    },
    mixins: [ Notifications ],
    methods: {
        ...mapActions(['personify']),
        removeCookies() {
            document.cookie =
                'Authorization= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
            document.cookie = 'User= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
            document.cookie = 'Refresh= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        },
        doPersonify(id){
            const age = 60 * 60 * 6; //6 hours
            const domain = window.location.hostname.replace(/^[^.]+\./g, "");
            console.log("DOMAIN", domain);
            this.removeCookies();
            this.personify(id).then(data => {
                document.cookie =
                    "Authorization=" +
                    data["access_token"] +
                    ";max-age=" +
                    age +
                    ";domain=" +
                    domain +
                    ";path=/";
                document.cookie =
                    "Refresh=" +
                    data["refresh_token"] +
                    ";max-age=" +
                    age +
                    ";domain=" +
                    domain +
                    ";path=/";
                document.cookie =
                    "User=" + id + ";max-age=" + age + ";domain=" + domain + ";path=/";
                window.open(process.env.VUE_APP_SITE_URL.replace(/\/+$/, '') + "/it/profilo");
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    }
}
