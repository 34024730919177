<template>
    <Dropdown
        v-model="value"
        :options="options"
        optionLabel="label"
        optionValue="value"
        :showClear='addEmpty'
        :placeholder="placeholder">
        <template #value="slotProps">
            <PlanRow :row='filterRow(slotProps.value)' :up='true' :short='short' />
        </template>
        <template #option="slotProps">
            <PlanRow :row='slotProps.option.row' :short='short' />
        </template>
    </Dropdown>
</template>

<script>

import PlansFetcher from '../../../mixins/dd_plans';
import PlanRow from './PlanRow';

export default {
    mixins: [PlansFetcher],
    components: { PlanRow },
    data(){
        return {};
    },
    props: ['modelValue', 'placeholder', 'addEmpty', 'short'],
    emits: ['update:modelValue'],
    methods: {
        filterRow(id){
            if (!this.dd_plans) return {};
            const found = this.dd_plans.filter(r => r && r.plan && r.plan.id && r.plan.id === id);
            if (found && found.length) return found[0];
            return {};
        }
    },
    computed: {
        options(){
            if (!this.dd_plans) return [];
            const plans = this.dd_plans;
            return plans.map(row => {
                let l = "piano";
               return { label: l, value: row.plan.id, row: row};
            });
        },
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>
