import { createNamespacedHelpers } from 'vuex';
const contStore = createNamespacedHelpers('Subscriptions');

export default {
    mounted(){
        this.getPlans();
    },
    data(){
        return {
            dd_plans: []
        };
    },
    computed: {
        ...contStore.mapGetters(['Plans/rows'])
    },
    methods: {
        ...contStore.mapActions(['Plans/fetchList']),
        initPlans(plans){
            this.dd_plans = plans;
        },
        getPlans(){
            if (this['Plans/rows'] && this['Plans/rows'].length){
                this.initPlans(this['Plans/rows']);
            } else {
                this['Plans/fetchList']().then(items => {
                    this.initPlans(items);
                }).catch(err => {
                    this.ShowStandardError(err.response);
                });
            }
        }

    }
}
