import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators'

const model = {
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    created_at: null,
    user_verified: false,
    verified: false,
    vc: "",
    referral_code: "",
    translations: {},
    subscriptions: []
};
//Try
const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, "user_id"),
    modelValidation: {
        model: {
            last_name: {
                required
            },
            email: {
                required
            }
        }
    },
    translationValidation: {
        model: {}
    }
};
