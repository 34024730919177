<template>
    <div v-if='model && !model.is_null'>
        <form @submit='save' @change='formIsDirty'>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 md:col-8'>
                    <span class='p-float-label'>
                        <span class='p-inputgroup'>
                            <InputText id='email'
                                       type='text'
                                       :readonly='true'
                                       :model-value="model.email"
                            />
                            <button class="p-button p-component p-button-icon-only p-button-help py-1" type="button">
                                <font-awesome-icon @click='doPersonify(model.id)'
                                                   class='text-white'
                                                   title='Impersona utente sul sito'
                                                   :style='{fontSize: "25px"}'
                                                   :icon="['fas', 'user-secret']"
                                                   :spin='personifying===model.id'/>
                                <span class="p-button-label">&nbsp;</span>
                                <span class="p-ink" style="height: 35px; width: 35px; top: 5.5px; left: -2.84375px;"></span>
                            </button>
                            <label for='email'>Email</label>
                        </span>
                        <small class="p-error">L'email deve essere cambiata dalla pagina del profilo sul sito.</small>
                    </span>
                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-4'>
                    <span class='p-float-label'>
                        <InputText id='last_name'
                                   type='text'
                                   v-model="v$.model.last_name.$model"
                                   :class="{'p-invalid': v$.model.last_name.$errors.length}"
                        />
                        <label for='last_name'>Cognome</label>
                    </span>
                </div>
                <div class='field col-12  md:col-4'>
                    <span class='p-float-label'>
                        <InputText id='first_name'
                                   type='text'
                                   v-model="model.first_name"
                                   />
                        <label for='first_name'>Nome</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-6  md:col-2'>
                    <BoolDropdown v-model='model.user_verified' placeholder='Utente verificato' />
                    <label class='sup'>Utente verificato</label>
                </div>
                <div class='field col-6  md:col-2'>
                    <BoolDropdown v-model='model.verified' placeholder='Email verificata' />
                    <label class='sup'>Email verificata</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-1'>
                <div class='field col-6  md:col-4'>
                    <span class='p-float-label'>
                        <InputText id='vc'
                                   type='text'
                                   :model-value="model.vc"
                                   :readonly='true'
                        />
                        <label for='vc'>Codice di verifica</label>
                    </span>
                </div>
                <div class='field col-6  md:col-4'>
                    <span class='p-float-label'>
                        <InputText id='referral_code'
                                   type='text'
                                   :model-value="model.referral_code"
                                   :readonly='true'
                        />
                        <label for='referral_code'>Codice referral</label>
                    </span>
                </div>
            </div>



            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Users/Users');
import useVuelidate from '@vuelidate/core';

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import BoolDropdown from '../../../components/4books/forms/BoolDropdown';
import Personify from '../../../mixins/personify';
export default {
    components: {
        BoolDropdown
    },
    data() {
        return {
        };
    },
    mounted() {
        this.model = this.row;
    },
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n) {
            this.model = n;
        },
    },
    methods: {
        ...mapActions(['savePlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        async save() {
            const result = await this.v$.$validate();
            if (!result) {
                this.ShowError('Errore', 'Alcuni campi del form non sono corretti');
                return;
            }

            if (!this.model.id){
                return;
            }
            const save = this.savePlain;

            save(this.model).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Utente modificato con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Navigations,
        Notifications,
        Personify
    ],
};
</script>
